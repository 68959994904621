.work-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width:100vw;
}
.page-content{
    width:45%;
}
.work-header {
    margin: 30px 0 5px 0;
}