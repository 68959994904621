.hockley-review-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.hockley-review-page h1 {
    font-size: 2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 10px;
}

.post-date {
    font-size: 0.9rem;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 20px;
}

.post-content {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom:20px;
}

.post-content p {
    margin-bottom: 15px;
}
